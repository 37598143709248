.settings-options-container {
	display: flex;
	flex-direction: row;
	column-gap: 16px;
	row-gap: 16px;
	align-items: center;
	flex-wrap: wrap;
}

.settings-header, .settings-options-container {
	margin-bottom: 36px;
	margin-top: 0;
}

.settings-theme-header {
	margin-top: 0;
}

.settings-save-container {
	display: flex;
	flex-direction: row;
	column-gap: 8px;
	row-gap: 4px;
}
