.confirmation-dialog {
	max-width: min(290px,80%);
}

.confirmation-dialog > h3 {
	margin: 0 0 16px 0;
}

.confirmation-dialog > .button-container {
	justify-content: space-between;
	width: 100%;
}

.accept-dialog > .button-container {
	justify-content: center;
}
