.form {
	display: flex;
	column-gap: 64px;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
}

.form.create-set-vocab-list {
	flex-direction: column;
}

.create-set-header, .form .create-set-input-row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.create-set-header > * {
	margin-top: 24px;
	margin-bottom: 4px;
}

.create-set-header > *, .form .create-set-input-row > * {
	flex: 1;
	flex-basis: 0;
}

.bulk-create-sets-section {
	margin-top: 16px;
}

.bulk-create-sets-header, .bulk-create-sets-header > * {
	display: flex;
	flex-direction: row;
	column-gap: 24px;
	row-gap: 8px;
	flex-wrap: wrap;
}

.bulk-create-sets-header > * {
	column-gap: 8px;
}

.bulk-create-sets-header > * > input {
	min-width: 36px;
}

.bulk-create-sets-text {
	min-height: 400px;
	font: inherit;
	background: transparent;
	color: inherit;
	border: 2px solid var(--overlay-color);
	padding: 8px;
	margin: 12px 0;
}

.bulk-create-sets-text:focus {
	outline: none;
}

.form .checkbox-list-container {
	flex: 1;
}

.checkbox-list {
	display: grid;
	grid-column-gap: 40px;
	grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
}

.form .checkbox-list-container a {
	color: inherit;
	text-decoration: none;
}

.form h3 {
	display: flex;
	column-gap: 4px;
	flex-direction: row;
	align-content: center;
}

.create-set-input-row > input[type=text]:first-child, .create-set-header > *:first-child {
	margin-right: 4px;
}

.create-set-input-row > input[type=text]:last-child, .create-set-header > *:last-child {
	margin-left: 4px;
}

input[type=text].set-title-input {
	flex: 1;
}
