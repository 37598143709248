.slider-overlay-content {
	width: 80%;
	max-width: 700px;
	height: 40%;
	max-height: 500px;
	align-items: center;
}

.slider-overlay-content label {
	margin: 16px 0 0 0;
}

.slider-overlay-content .continue-button {
	margin: 24px 0 0 0;
}

.slider-overlay-content > .slider-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	column-gap: 12px;
	justify-content: center;
	align-items: center;
}

.slider-overlay-content > .slider-container input {
	min-width: 0;
	min-width: 26px;
	width: 26px;
}

.test-options-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	column-gap: 32px;
	flex-wrap: wrap;
}


@media screen and (max-width: 500px) {
	.slider-overlay-content {
		width: 75%;
	}
}

@media screen and (max-height: 700px) {
	.slider-overlay-content {
		height: 60%;
	}
}