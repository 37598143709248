.answer-input-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.answer-input-container > input {
	max-width: 500px;
	flex-grow: 1;
	margin-right: 8px;
	font-size: 36px;
}

.answer-input-container.answer-input-container--answer-entered > input {
	caret-color: transparent;
}

.answer--correct {
	color: #3ac535;
}

.answer--incorrect {
	color: #ff5252;
}

.current-prompt {
	font-size: 46px;
}

.progress-end-incorrect-answers {
	display: table;
	table-layout: fixed;
	width: 100%;
	margin-top: 24px;
	row-gap: 8px;
}

.progress-end-incorrect-answers > div {
	display: table-row;
}

.progress-end-incorrect-answers > div > * {
	display: table-cell;
	word-wrap: break-word;
	padding-top: 2px;
	padding-bottom: 2px;
  	border-left: 8px solid transparent;
}

.progress-end-incorrect-answers > div > *:first-child {
  	border-left: 0;
}

.progress-end-button-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	word-wrap: break-word;
	align-items: center;
	column-gap: 8px;
	row-gap: 8px;
}

.progress-end-button-container > .button {
	margin: 0;
}

.form-submit {
	display: none;
}

.progress-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.progress-stats {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
  	margin: 0 24px;
}

.progress-stat-row-container {
	display: flex;
	flex-direction: row;
	column-gap: 48px;
	margin-bottom: 12px;
	flex-wrap: wrap;
}

.progress-bar {
	width: 100%;
	border: 1px solid var(--overlay-color);
	border-radius: 16px;
	overflow: hidden;
}

.progress-bar > div {
	height: 100%;
	background-color: var(--primary-color);
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
}

.progress-bar > div > p {
	padding-left: 8px;
	margin: 0 0 -2px 0;
}

.hide-virtual-keyboard-button {
	margin: 16px auto;
}

.simple-keyboard.hg-theme-default {
	background-color: var(--background-color);
}

.simple-keyboard.hg-theme-default .hg-button {
	background-color: var(--background-color--light);
	border: 1px solid var(--overlay-color);
	box-shadow: none;
}

.simple-keyboard.hg-theme-default .hg-button:hover, .simple-keyboard.hg-theme-default .hg-button:focus {
	background-color: var(--overlay-color);
}

@media screen and (max-width: 880px) {
	.current-prompt {
		font-size: 36px;
	}
	.answer-input-container > input {
		font-size: 32px;
	}
}

@media screen and (max-width: 700px) {
	.current-prompt {
		font-size: 30px;
	}
	.answer-input-container > input {
		font-size: 26px;
	}
}

@media screen and (max-width: 500px) {
	.current-prompt {
		font-size: 26px;
	}
	.answer-input-container > input {
		font-size: 24px;
	}
}