.vocab-list {
	display: table;
	table-layout: fixed;
	column-gap: 10px;
	width: 100%;
	margin-top: 24px;
	row-gap: 8px;
}

.vocab-list > div {
	display: table-row;
}

.vocab-list > div > * {
	display: table-cell;
	word-wrap: break-word;
	padding-top: 2px;
	padding-bottom: 2px;
}

.vocab-list > div.vocab-list-header > * {
	padding-bottom: 8px;
}

.vocab-list > div > *:first-child {
	border-right: 8px solid transparent;
}

.set-page-group-button-container {
	width: 80%;
}

.set-page-group-overlay-content {
	min-width: min(500px,80%);
}

.no-groups-message-list {
	padding: 0 18px;
	margin: 4px 0;
}